<template>
  <div>
    Consectetur aliquip dolore minim occaecat nostrud occaecat deserunt cillum
    anim nisi.
  </div>
</template>
<script>
export default {
  name: "ReportSchedule",
};
</script>
